import moment from 'moment'
import { Buffer } from 'buffer'
import { ROOM_TYPES } from './constants'

export const buildGallery = (images) => {
  const galleryImages = []
  if (images.length > 1) {
    galleryImages.push([images[0], images[1]])
  }
  if (images.length > 2) {
    galleryImages.push([images[2]])
  }
  if (images.length > 4) {
    galleryImages.push([images[3], images[4]])
  }
  if (images.length > 5) {
    galleryImages.push([images[5]])
  }
  if (images.length > 7) {
    galleryImages.push([images[6], images[7]])
  }
  if (images.length > 8) {
    galleryImages.push([images[8]])
  }
  if (images.length > 10) {
    galleryImages.push([images[9], images[10]])
  }
  if (images.length > 11) {
    galleryImages.push([images[11]])
  }
  return galleryImages
}
export const Linkify = (inputText) => {
  //URLs starting with http://, https://, or ftp://
  const replacePattern1 = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim
  let replacedText = inputText.replace(
    replacePattern1,
    '<a href="$1" target="_blank">$1</a>'
  )

  //URLs starting with www. (without // before it, or it'd re-link the ones done above)
  const replacePattern2 = /(^|[^\/])(www\.[\S]+(\b|$))/gim
  replacedText = replacedText.replace(
    replacePattern2,
    '$1<a href="http://$2" target="_blank">$2</a>'
  )

  //Change email addresses to mailto:: links
  const replacePattern3 = /(\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,6})/gim
  replacedText = replacedText.replace(
    replacePattern3,
    '<a href="mailto:$1">$1</a>'
  )

  return replacedText
}

export const mapApiHotels = (tgHotels, apiHotels, hotels) => {
  // Filter the hotels to get the hotels found in the api
  const mappedHotels = hotels.filter(
    (hotel) => hotel.hotel_ref && apiHotels.includes(hotel.hotel_ref.toString())
  )
  const returnedHotels = []
  // Now we need to slot the room prices from travelgate into the hotel rooms
  for (const hotel of mappedHotels) {
    const rooms = tgHotels.filter(
      (option) => option.hotelCode === hotel.hotel_ref.toString()
    )
    returnedHotels.push({
      ...hotel,
      apiRooms: rooms,
    })
  }
  return returnedHotels
}

export const setBasePrice = (hotels, checkIn, checkOut, showFrom = false) => {
  if (checkIn && checkOut && !showFrom) {
    return hotels
      ? hotels.map((hotel) => {
          let basePrice = 0
          if (hotel.hasOwnProperty('apiRooms')) {
            for (const room of hotel.apiRooms) {
              const days = checkOut.diff(checkIn, 'days')
              // console.log(hotel.name, room.price.net / days)
              if (basePrice === 0 || room.price.net / days < basePrice) {
                basePrice = room.price.net / days
              }
            }
          } else {
            // basePrice = hotel.basePrice
            for (const room of hotel.rooms) {
              if (room.price && room.price.length > 0) {
                for (const price of room.price) {
                  if (price.amount) {
                    const startDate = moment(price.startDate)
                    const endDate = moment(price.endDate)
                    if (
                      startDate &&
                      endDate &&
                      checkIn.isSameOrAfter(startDate) &&
                      checkOut.isSameOrBefore(endDate)
                    ) {
                      if (basePrice === 0 || price.amount < basePrice) {
                        basePrice = price.amount
                      }
                    } else if (
                      checkIn.isSameOrAfter(startDate) &&
                      checkOut.isSameOrAfter(endDate) &&
                      moment(endDate).isSameOrAfter(checkIn)
                    ) {
                      if (basePrice === 0 || price.amount < basePrice) {
                        basePrice = price.amount
                      }
                    }
                  }
                }
              }
            }
          }
          return {
            ...hotel,
            basePrice: basePrice > 0 ? basePrice : hotel.basePrice,
          }
        })
      : []
  } else {
    return hotels
      ? hotels.map((hotel) => {
          return {
            ...hotel,
            basePrice: hotel.basePrice,
          }
        })
      : []
  }
}
export const filterHotels = (fHotels, fAreas, fTypes, fTab) => {
  let hotelsFiltered = [];
  // console.log(fTypes)
  for (const hotel of fHotels) {
    let searchFilterAreas = []
    let searchFilterTypes = []
    if (hotel.hasOwnProperty('filterAreas')) {
      searchFilterAreas = hotel.filterAreas
    } else {
      searchFilterAreas = hotel.searchFilterAreas
    }
    if (hotel.hasOwnProperty('filterTypes')) {
      searchFilterTypes = hotel.filterTypes
    } else if (hotel.hasOwnProperty('advanced_filter_matrices')) {
      searchFilterTypes = hotel.advanced_filter_matrices
    } else {
      searchFilterTypes = hotel.searchFilterTypes
    }
    if (fAreas.length > 0 && fTypes.length > 0) {
      const filteredAreas =
        typeof searchFilterAreas !== 'undefined'
          ? searchFilterAreas.filter((area) => {
              return fAreas.includes(area.name)
            })
          : []
      const filteredTypes =
        typeof searchFilterTypes !== 'undefined'
          ? searchFilterTypes.filter((type) => {
              return fTypes.includes(type.name)
            })
          : []
      if (filteredAreas.length > 0 && filteredTypes.length > 0) {
        hotelsFiltered.push(hotel)
      }
    } else if (fTypes.length > 0) {
      let filteredTypes = []
      console.log(searchFilterTypes)
      if (typeof searchFilterTypes !== 'undefined') {
        filteredTypes = searchFilterTypes.filter((type) => {
          return fTypes.includes(type.name)
        })
      }
      if (filteredTypes.length > 0) {
        hotelsFiltered.push(hotel)
      }
    } else if (fAreas.length > 0) {
      let filteredAreas = []
      if (typeof searchFilterAreas !== 'undefined') {
        filteredAreas = searchFilterAreas.filter((area) => {
          return fAreas.includes(area.name)
        })
      }
      if (filteredAreas.length > 0) {
        hotelsFiltered.push(hotel)
      }
    } else {
      hotelsFiltered.push(hotel)
    }
  }
  if (fTab !== null && fTab.Name !== 'All') {

    return hotelsFiltered.filter((hotel) => {
      if (hotel.search_tabs && hotel.search_tabs.length > 0) {
        return hotel.search_tabs.filter((tab) => {
          return tab.name === fTab.Name
        }).length > 0
      } else {
        return (
          (hotel.filterTab && hotel.filterTab.name === fTab.name) ||
          (hotel.search_tab && hotel.search_tab.name === fTab.Name)
        )
      }
      
    })
  } else if (fTab && fTab.Name === 'All') {
    //
    return fHotels
  }
  return hotelsFiltered
}

export const buildQueryString = (o) => {
  const queryString = Object.keys(o)
    .map((key) => {
      let value = o[key]
      if (typeof value === 'object') {
        if (
          (key === 'checkinDate' || key === 'checkoutDate') &&
          value !== null &&
          value !== ''
        ) {
          value = value.format('YYYY-MM-DD')
        } else if (value && value.length > 0) {
          value = Buffer.from(JSON.stringify(value)).toString('base64')
        } else {
          value = ''
        }
      } else {
        if (
          (key === 'checkinDate' || key === 'checkoutDate') &&
          value !== null &&
          value !== ''
        ) {
          value = moment(value).format('YYYY-MM-DD')
        }
      }
      if (key === 'tab') {
        value = value ? value.toLowerCase() : 'stays'
      }
      return encodeURIComponent(key) + '=' + encodeURIComponent(value)
    })
    .join('&')
  return queryString
}
export const sortByDate = (a, b) => {
  if (moment(a.updated_at).isBefore(moment(b.updated_at))) {
    return 1
  } else if (moment(a.updated_at).isAfter(moment(b.updated_at))) {
    return -1
  }
  return 0
}
export const sortByName = (a, b) => {
  return a.name.localeCompare(b.name)
}
export const sortByRandom = (a, b) => {
  return Math.random() > 0.5 ? 1 : -1
}
export const sortByRating = (a, b) => {
  // console.log(a.name, a.tripadvisor_rating, b.name, b.tripadvisor_rating)
  if (a.tripadvisor_rating > b.tripadvisor_rating) {
    return 1
  } else if (a.tripadvisor_rating < b.tripadvisor_rating) {
    return -1
  }
  return 0
}
export const sortByPrice = (a, b) => {
  if (a.basePrice > b.basePrice) {
    return 1
  } else if (a.basePrice < b.basePrice) {
    return -1
  }
  return 0
}

export const sortActivityByPrice = (a, b) => {
  if (a.price.adult > b.price.adult) {
    return 1
  } else if (a.price.adult < b.price.adult) {
    return -1
  }
  return 0
}
export const reorderActivities = (items) => {
  if (items.length > 0) {
    const tmpItems = [...items]
    return tmpItems.sort(sortActivityByPrice)
  }
  return items
}

export const calculatePrice = (item, checkinDate, checkoutDate) => {
  let basePrice = 0
  if (item.hasOwnProperty('packageRooms') && item.packageRooms.length > 0) {
    for (const room of item.packageRooms) {
      room.rates.forEach((rate) => {
        if (
          checkinDate >= moment(rate.startDate) &&
          checkinDate <= moment(rate.endDate)
        ) {
          if (
            basePrice === 0 ||
            basePrice > item.fromPrice + rate.adults + rate.children
          ) {
            basePrice = item.fromPrice + rate.adults + rate.children
          }
        }
      })
    }
    return basePrice > 0 ? basePrice : item.fromPrice
  } else if (item.fromPrice) {
    return item.fromPrice
  } else if (item.basePrice) {
    return item.basePrice
  }
}

export const buildSortBy = (sortBy, filtered) => {
  if (sortBy[0] === 'random') {
    // filtered.sort(sortByRandom)
    filtered.sort(sortByDate)
  }
  if (sortBy[0] === 'rating') {
    filtered.sort(sortByRating)
  }
  if (sortBy[0] === 'price') {
    filtered.sort(sortByPrice)
  }
  if (sortBy[0] === 'alphabetical') {
    filtered.sort(sortByName)
  }
  if (sortBy[1] === 'desc') {
    filtered.reverse()
  }
  return filtered
}

export const buildSearchParams = (router, searchParams) => {
  if (router.query.hasOwnProperty('guests')) {
    searchParams.guests = JSON.parse(
      Buffer.from(String(router.query.guests), 'base64').toString()
    )
  }
  if (router.query.hasOwnProperty('checkinDate')) {
    searchParams.checkinDate = router.query.checkinDate
  } else {
    // searchParams.checkinDate = null
  }
  if (router.query.hasOwnProperty('checkoutDate')) {
    searchParams.checkoutDate = router.query.checkoutDate
  } else {
    // searchParams.checkoutDate = null
  }
  if (
    router.query.hasOwnProperty('filterType') &&
    router.query.filterType !== ''
  ) {
    searchParams.filterType = JSON.parse(
      Buffer.from(String(router.query.filterType), 'base64').toString()
    )
  } else {
    searchParams.filterType = []
  }
  if (
    router.query.hasOwnProperty('filterArea') &&
    router.query.filterArea !== ''
  ) {
    searchParams.filterArea = JSON.parse(
      Buffer.from(String(router.query.filterArea), 'base64').toString()
    )
  }
  if (
    router.query.hasOwnProperty('filterDuration') &&
    router.query.filterDuration !== ''
  ) {
    searchParams.filterDuration = JSON.parse(
      Buffer.from(String(router.query.filterDuration), 'base64').toString()
    )
  }
  if (router.query.hasOwnProperty('query') && router.query.query !== '') {
    searchParams.query = encodeURIComponent(router.query.query)
  }
  return searchParams
}
export const rebuildFilters = (categories, section) => {
  let list = []
  for (const category of categories) {
    if (section === 'all') {
      list = [...list, ...category.category[0].search_filter_types]
    } else {
      list = [
        ...list,
        ...category.category[0].search_filter_types.filter(
          (type) => type[section] && type[section].length > 0
        ),
      ]
    }
  }
  return list
}
export const rebuildTabs = (tabs) => {
  let searchTabs = []

  return searchTabs
}

export const calculateRoomPrice = (room, type, checkInDate, checkOutDate) => {
  // console.log(room, type, checkInDate, checkOutDate)
  let roomType = '',
    roomPrice = 0,
    roomId = 0
  const guestCheckIn = moment(checkInDate)
  const guestCheckOut = moment(checkOutDate)
  const guestNights = guestCheckOut.diff(guestCheckIn, 'days')
  for (const price of room[0].price) {
    if (
      guestCheckIn.isSameOrAfter(price.startDate) &&
      guestCheckOut.isSameOrBefore(price.endDate) &&
      (!type || type === '' || type === price.roomType)
    ) {
      // console.log('Stay is in room dates')
      // Stay dates are in a price zone
      roomType = ROOM_TYPES[price.roomType]
      roomId = price.id
      roomPrice = Math.ceil(price.amount) * guestNights
      break
    } else {
      if (
        guestCheckIn.isSameOrAfter(price.startDate) &&
        guestCheckOut.isSameOrAfter(price.endDate) &&
        moment(price.endDate).isSameOrAfter(guestCheckIn)
      ) {
        roomPrice =
          Math.ceil(price.amount) *
          moment(price.endDate).add(1, 'day').diff(guestCheckIn, 'days')
        roomType = ROOM_TYPES[price.roomType]
        roomId = price.id
        for (const r of room[0].price) {
          if (
            guestCheckOut.isSameOrAfter(moment(r.startDate)) &&
            guestCheckOut.isSameOrBefore(r.endDate) &&
            price.roomType === r.roomType
          ) {
            roomType = ROOM_TYPES[price.roomType]
            roomId = price.id
            roomPrice +=
              Math.ceil(r.amount) *
              guestCheckOut.diff(moment(r.startDate), 'days')
            break
          }
        }
        break
      }
    }
  }
  // console.log({ roomId: roomId, type: roomType, price: { net: roomPrice } })
  return { roomId: roomId, type: roomType, price: { net: roomPrice } }
}

export const generateSitemap = (section, dataObj) => {
  let xml = ''
  for (const data of dataObj.data) {
    xml += `<url>
        <loc>${process.env.NEXT_PUBLIC_SITE_URL}/${section}/${data.slug}</loc>
        <lastmod>${moment(data.updated_at).format('YYYY-MM-DD')}</lastmod>
      </url>`
  }
  return `<?xml version="1.0" encoding="UTF-8"?>
<urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9">
  ${xml}
</urlset>`
}

export const generateBlogSitemap = (categories, posts) => {
  let xml = ''
  for (const category of categories.data) {
    xml += `<url>
        <loc>${process.env.NEXT_PUBLIC_SITE_URL}/blog/${category.slug}</loc>
        <lastmod>${moment(category.updated_at).format('YYYY-MM-DD')}</lastmod>
      </url>`
  }
  for (const data of posts.data) {
    for (const cat of data.categories) {
      if (cat.slug !== 'all-stories') {
        xml += `<url>
        <loc>${process.env.NEXT_PUBLIC_SITE_URL}/blog/${cat.slug}/${
          data.slug
        }</loc>
        <lastmod>${moment(data.updated_at).format('YYYY-MM-DD')}</lastmod>
      </url>`
      }
    }
  }
  return `<?xml version="1.0" encoding="UTF-8"?>
<urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9">
  ${xml}
</urlset>`
}
